import { ProcessCellForExportParams } from 'ag-grid-community';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor() {}

    removeUndefinedFromObject(object: Record<string, unknown>): Record<string, unknown> {
        Object.keys(object).forEach((key: string) => (object[key] === undefined || object[key] === '' ? delete object[key] : ''));
        return object;
    }

    removeUndefinedAndNullFromObject(object: Record<string, unknown>): Record<string, unknown> {
        Object.keys(object).forEach((key: string) =>
            object[key] === undefined || object[key] === null || object[key] === '' ? delete object[key] : ''
        );
        return object;
    }

    downloadDataAsCsv(csv: string, fileName: string): void {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.style.display = 'none';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    }

    formatGridNumbersForExport(params: ProcessCellForExportParams): string {
        if (isNaN(+params.value)) {
            return params.value;
        }
        // % 1 === 0 to check if value is integer
        const formattedValue = +params.value % 1 === 0 ? `${params.value}` : (+params.value).toFixed(2).replace('.', ',');
        return formattedValue;
    }
}
